.eligibility-form {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input, 
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
/* General form styling */
body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px;
}

.eligibility-form {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

h2 {
    color: #2a3c7e;
    margin-bottom: 20px;
}

h3 {
    color: #29a3a3;
}

p {
    font-size: 0.9rem;
    color: #555;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input, select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

/* Name fields specific styling */
.name-fields {
    display: flex;
    gap: 10px;
}

.name-fields input {
    flex: 1;
}

/* Address fields styling */
.address-fields {
    display: flex;
    gap: 10px;
}

.address-fields input, .address-fields select {
    flex: 1;
}

button {
    background-color: #2a3c7e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

button:hover {
    background-color: #1e2c61;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .name-fields, .address-fields {
        flex-direction: column;
    }

    button {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .eligibility-form {
        padding: 10px;
    }

    input, select {
        padding: 8px;
        font-size: 0.9rem;
    }

    button {
        font-size: 0.9rem;
    }
}
